<template>
  <div>
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
    >
      <template slot="toolbar">
        <el-form-item
          label="订单编号"
          class="col-auto"
        >
          <el-input
            v-model="params.sn"
            size="small"
            placeholder="请输入订单编号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="提交时间"
          class="col-auto ml-2"
        >
          <el-date-picker
            v-model="params.order_time_range"
            style="width: 220px"
            type="daterange"
            align="center"
            :editable="false"
            unlink-panels
            size="small"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :picker-options="{ disabledDate(time) { return time.getTime() > Date.now() }, shortcuts: MixinPickerShortcuts }"
          ></el-date-picker>
        </el-form-item>
        <div class="col-auto ml-2">
          <el-button
            @click="searchEvent"
            size="small"
            type="primary"
          >搜索</el-button>
        </div>
        <div class="col"></div>
        <div class="col-auto ml-2">
          <el-button
            size="small"
            type="primary"
            @click="handleExportOrders"
            style="margin-left: 5px"
          >导出服务订单</el-button>
        </div>
      </template>
      <template slot="table-columns">
        <!-- <el-table-column
          type="selection"
          width="55"
        >
        </el-table-column> -->
        <!--订单编号-->
        <el-table-column
          prop="sn"
          label="订单编号"
          width="150"
        />
        <!--提交时间-->
        <el-table-column
          prop="create_time"
          :formatter="MixinUnixToDate"
          label="提交时间"
          width="150"
        />
        <!-- <el-table-column prop="member_name" label="用户账号"/> -->
        <!--订单总额-->
        <el-table-column label="订单金额" width="100">
          <template slot-scope="scope">
            {{ scope.row.price | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态" width="100">
          <template slot-scope="scope">
            {{ fService_status(scope.row.pay_status) }}
          </template>
        </el-table-column>
        <!--支付方式-->
        <el-table-column label="支付方式" width="100">
          <template slot-scope="scope">
            {{scope.row.payment_type === 'ONLINE'?'在线支付':'线下支付'}}
          </template>
        </el-table-column>
        <!--订单来源-->
        <el-table-column
          prop="shop_name"
          label="订单来源"
          min-width="200"
          :show-overflow-tooltip="true"
        />
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="MixinPageSizes"
        :page-size="tableData.page_size"
        :layout="MixinTableLayout"
        :total="tableData.data_total"
        background
      >
      </el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_order from "@/api/order";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  name: "serveorderList",
  components: { EnTableLayout },
  data() {
    return {
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        sn: "",
        order_time_range: [],
      },
      // 列表数据
      tableData: { data: [{}] },
      // 高级搜索数据
      advancedForm: {},
      orderType: 1,
    };
  },
  filters: {
    paymentTypeFilter(val) {
      return val === "ONLINE" ? "在线支付" : "线下支付";
    },
  },
  mounted() {
    this.GET_OrderList();
  },
  activated() {
    this.GET_OrderList();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_OrderList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_OrderList();
    },

    /** 搜索事件触发 */
    searchEvent() {
      this.params = {
        ...this.params,
        ...this.advancedForm,
      };
      delete this.params.start_time;
      delete this.params.end_time;
      if (this.params.order_time_range) {
        this.params.start_time = this.params.order_time_range[0];
        this.params.end_time = this.params.order_time_range[1];
        // delete this.params.order_time_range
      }
      Object.keys(this.advancedForm).forEach((key) => delete this.params[key]);
      this.params.page_no = 1;
      this.GET_OrderList();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent() {
      this.params = {
        ...this.params,
        ...this.advancedForm,
      };
      delete this.params.start_time;
      delete this.params.end_time;
      if (this.advancedForm.order_time_range) {
        this.params.start_time = this.advancedForm.order_time_range[0];
        this.params.end_time = this.advancedForm.order_time_range[1];
      }
      delete this.params.order_sn;
      delete this.params.order_time_range;
      this.params.page_no = 1;
      this.GET_OrderList();
    },

    /** 查看、操作订单 */
    handleOperateOrder(index, row) {
      this.$router.push({
        name: "orderDetail",
        params: {
          sn: row.sn,
          callback: this.GET_OrderList,
        },
      });
    },
    /** 导出订单 */
    handleExportOrders() {
      const { advancedForm: params } = this;
      params.page_no = 1;
      params.page_size = 99999;
      API_order.serviceOrder(params).then((response) => {
        const json = {
          sheet_name: "服务订单列表",
          sheet_values: response.data.map((item) => ({
            订单编号: item.sn,
            提交时间: Foundation.unixToDate(item.create_time),
            订单金额: Foundation.formatPrice(item.price),
            订单状态: item.pay_status === "PAY_NO" ? "未支付" : "已支付",
            支付方式: item.payment_type === "ONLINE" ? "在线支付" : "线下支付",
            订单来源: item.shop_name,
          })),
        };
        this.MixinExportJosnToExcel(json, "服务订单列表");
      });
    },
    fService_status(status) {
      switch (status) {
        case "PAY_NO":
          return "未支付";
        case "PAY_YES":
          return "已支付";
        default:
          return "";
      }
    },

    /** 获取订单列表 */
    GET_OrderList() {
      this.loading = true;
      if (!this.params.order_time_range) delete this.params.order_time_range;
      if (!this.params.sn) delete this.params.sn;
      API_order.serviceOrder(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

